<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="templates"
          mode="remote"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->

            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-if="props.column.field === 'action'"
            >
              <div
                v-if="$can(`${$route.meta.permission}.edit`)"
                class="cursor-pointer"
                @click="$router.push(`/mail-templates/${props.row.id}/edit`)"
              >
                <feather-icon size="16" icon="Edit2Icon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.delete`)"
                class="cursor-pointer"
                @click="destroy(props.row.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </div>
            </span>
            <span v-else-if="props.column.field === 'status'">
              {{ props.row.active ? $t("Active") : $t("Inactive") }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <div class="row">
        <div class="col-md-4" v-for="row in templates" :key="row.id">
          <div class="card mb-1">
            <div class="card-header">
              <h5 class="card-title">{{ row.subject }}</h5>
              <div
                class="card-actions d-flex gap-2"
                v-if="
                  $can(`${$route.meta.permission}.edit`) ||
                  $can(`${$route.meta.permission}.delete`)
                "
              >
                <button
                  v-if="$can(`${$route.meta.permission}.edit`)"
                  @click="$router.push(`/mail-templates/${row.id}/edit`)"
                  class=""
                >
                  <feather-icon icon="Edit2Icon" size="18" />
                </button>
                <button
                  v-if="$can(`${$route.meta.permission}.delete`)"
                  @click="destroy(row.id)"
                  class=""
                >
                  <feather-icon icon="TrashIcon" size="18" />
                </button>
              </div>
            </div>
            <div class="card-body">
              <p class="card-text">
                <strong>{{ $t("Status") }}</strong>
                {{ row.active ? $t("Active") : $t("Inactive") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--====================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PageHeader,
  },
  computed: {
    ...mapGetters("mailTemplates", ["templates"]),
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Mail Template"),
          icon: "PlusIcon",
          path: "/mail-templates/create",
          permission: `${this.$route.meta.permission}.create`,
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Mail Templates"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Subject"),
          field: "subject",
          sortable: false,
        },
        {
          label: this.$t("Status"),
          field: "status",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {};
  },

  async created() {
    this.loadItems();
  },

  methods: {
    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        if (!this.templates?.length)
          await this.$store.dispatch("mailTemplates/list");
      } catch (e) {
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store
          .dispatch("mailTemplates/destroy", { id: id })
          .finally(() => {
            this.loadItems();
          });
      }
    },
  },
};
</script>
